<template>
  <div>
  </div>
</template>

<script>
export default {
  
  data() {
    return {}
  },
  created() {
    console.log(this.$router.push("/commodity/productEdit"));
  }
}
</script>